import React, { useEffect, useState } from "react";
import { ReactComponent as CheckImg } from "../../../../assets/img/check-mark.svg";
import { ReactComponent as CloseImage } from "../../../../assets/img/close.svg";
import { ReactComponent as Trash } from "../../../../assets/img/trash.svg";
import { ReactComponent as EditIcon } from "../../../../assets/img/adminChange.svg";
import { ReactComponent as UserPlus } from "../../../../assets/img/Userplus.svg";
import Pagination from "../../../../components/Pagination/Pagination";
import { Button, Input } from "../../../../components/ui";
import { useTheme } from "../../../../components/ui/ChangeTheme/context/ThemeContext";
import axiosInstance from "../../../../utils/axiosInstance";
import { Modal } from "../Modal/Modal";
import styles from "./Users.module.css";
import { useGetUsers } from "./api/api";
import { useTranslation } from "react-i18next";
import SelectOne from "../../../../components/SelectOne/SelectOne";
import { RowPerPage } from "../../../../components/RowPerPage/RowPerPage";
import Aside from "../../../../components/Aside/Aside";
import { useUserData } from "../../../../context/UserProvider";
import UserSkeleton from "./api/UsersSkeleton/UserSkeleton";
import { getData } from "../../../MapPage/api";
import { toast } from "react-toastify";
import { use } from "i18next";
const rowsPerPageOptions = [5, 10, 20];

export const Users = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [editingIndex, setEditingIndex] = useState(null);
  const [page, setPage] = useState(1);
  const [totalString, setTotalString] = useState(rowsPerPageOptions[1]);
  const { data, pageCount, length, fetchData, isLoading } = useGetUsers(
    page,
    totalString
  );
  const [role, setRole] = useState("");
  const [editedData, setEditedData] = useState(data);
  const [isOpenAsideUser, setIsOpenAsideUser] = useState(false);
  const { userData } = useUserData();

  const roleMap = {
    [t("Партнер")]: 2,
    [t("Дев")]: 4,
    [t("Администратор")]: 8,
  };

  const Roles = [
    {
      value: 2,
      label: t("Партнер"),
    },
    {
      value: 4,
      label: t("Дев"),
    },
    {
      value: 8,
      label: t("Администратор"),
    },
  ];

  useEffect(() => {
    setEditedData(data);
  }, [data]);

  const handleEdit = (index) => {
    setEditingIndex(index);
  };

  const handleSave = async (index) => {
    const updatedUser = editedData[index];
    const { username, role, newPassword } = updatedUser;
    const updateData = { role };

    if (username !== data[index].username) {
      updateData.userName = username;
    }

    if (Object.keys(updateData).length > 0) {
      try {
        const responseData = await axiosInstance.post("/edit-user", {
          userName: username,
          prevUsername: data[index].username,
          role: updateData.role,
          usernameToFind: !newPassword ? undefined : username,
          password: !newPassword ? undefined : newPassword,
        });
        if (responseData) {
          setEditedData((prev) => {
            const newData = [...prev];
            newData[index] = {
              ...newData[index],
              username: updateData.userName || newData[index].username,
              newPassword: "",
            };
            return newData;
          });
          toast.success(t("Пользователь успешно изменен!"));
        }
      } catch (error) {
        console.error(error);
        toast.error(t("Ошибка при сохранении пользователя"));
      }
    }
    setEditingIndex(null);
  };

  const handleExit = () => {
    setEditingIndex(null);
    setEditedData(data);
  };

  const handleChange = (value) => {
    const { e, index, field } = value;
    const updatedUsers = editedData.map((user, idx) => {
      if (idx === index) {
        return { ...user, [field]: e };
      }
      return user;
    });
    setEditedData(updatedUsers);
  };

  const handleDeleteUser = async (id, name) => {
    try {
      const data = await getData({
        url: "/delete-user",
        body: {
          id: id,
        },
      });
      if (data) {
        toast.success(t(`Пользователь`) + " " + name + " " + t(`удален!`));
        fetchData();
      }
    } catch (error) {
      toast.error(t(`Произошла ошибка при удалении пользователя`));
      console.log(error);
    }
  };

  const getRole = (roleId) => {
    return Object.keys(roleMap).find((key) => roleMap[key] === roleId);
  };
  const checkAdmin = (roleId) => {
    return roleId >= 5;
  };

  return (
    <>
      <div className={styles.container}>
        {data.length ? (
          <>
            <Aside
              width={320}
              title={t("Добавление пользователя")}
              isOpen={isOpenAsideUser}
              setIsOpen={setIsOpenAsideUser}
            >
              <Modal
                Roles={Roles}
                setRole={setRole}
                fetchData={fetchData}
                setIsOpen={setIsOpenAsideUser}
              />
            </Aside>
            <div
              data-theme={theme}
              className={
                styles[theme === "dark" ? "dark_users_block" : "users_block"]
              }
            >
              <h1 className={styles.block_name}>{t("Пользователи")}</h1>
              <div className={styles["users_block_actions"]}>
                <div className={styles["all-users"]}>
                  <span>
                    {t("Всего пользователей")}: {length}
                  </span>
                </div>
                <div className={styles.button}>
                  <Button
                    style={{ color: theme === "light" ? "#000" : "#666" }}
                    className={styles.buttonAdd}
                    variant="outline"
                    onClick={() => setIsOpenAsideUser(true)}
                    EndIcon={UserPlus}
                  >
                    {t("Добавить пользователя")}
                  </Button>
                </div>
              </div>
              <div className={styles[theme === "dark" ? "dark_data" : "data"]}>
                <div
                  className={
                    styles[theme === "dark" ? "dark_header" : "header"]
                  }
                >
                  <div className={styles.header_content}>
                    <h2 className={styles.header_content_item}>{t("Логин")}</h2>
                    <h2 className={styles.header_content_item}>
                      {t("Пароль")}
                    </h2>
                    <h2 className={styles.header_content_item}>{t("Роль")}</h2>
                    <div className={styles.header_content_item}></div>
                  </div>
                </div>
                {isLoading
                  ? Array.from({ length: totalString }).map((_, index) => (
                      <UserSkeleton key={index} theme={theme} />
                    ))
                  : editedData.map((user, index) => (
                      <div
                        className={
                          styles[theme === "dark" ? "dark_user" : "user"]
                        }
                        key={index}
                      >
                        <div className={styles.user_info}>
                          {editingIndex === index ? (
                            <Input
                              type="text"
                              value={user.username}
                              onChange={(e) =>
                                handleChange({
                                  e: e.target.value,
                                  index,
                                  field: "username",
                                })
                              }
                              className={styles.editUserNameInput}
                            />
                          ) : (
                            <h2 title={user.username}>{user.username}</h2>
                          )}
                        </div>
                        <div className={styles.user_info}>
                          {editingIndex === index ? (
                            <input
                              type="text"
                              value={user.newPassword || ""}
                              placeholder={t("Новый пароль")}
                              onChange={(e) =>
                                handleChange({
                                  e: e.target.value,
                                  index,
                                  field: "newPassword",
                                })
                              }
                              className={styles.editPasswordInput}
                            />
                          ) : (
                            <h2>********</h2>
                          )}
                        </div>

                        <div className={styles.user_info}>
                          {editingIndex === index ? (
                            <>
                              <SelectOne
                                className={styles.select}
                                options={Roles}
                                subName={
                                  getRole(user.role) || t("Администратор")
                                }
                                onChange={(e) => {
                                  handleChange({ e, index, field: "role" });
                                }}
                              />
                            </>
                          ) : (
                            <h2 className={styles.role}>
                              {getRole(user.role)}
                            </h2>
                          )}
                        </div>
                        <div className={styles.interface}>
                          {editingIndex === index ? (
                            <div className={styles.editButtons}>
                              <button
                                className={styles.save}
                                onClick={() => handleSave(index)}
                              >
                                <CheckImg
                                  style={{
                                    stroke: "#7b818a",
                                    width: "18px",
                                    height: "18px",
                                  }}
                                />
                              </button>
                              <button
                                onClick={handleExit}
                                className={styles.exit}
                              >
                                <CloseImage
                                  style={{
                                    stroke: "#7b818a",
                                    width: "30px",
                                  }}
                                />
                              </button>
                              <button
                                onClick={() =>
                                  handleDeleteUser(user.id, user.username)
                                }
                                className={styles.delete}
                              >
                                <Trash style={{ width: "20px" }} />
                              </button>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <button
                                className={styles.edit}
                                onClick={() => handleEdit(index)}
                              >
                                <EditIcon />
                              </button>
                              <button
                                className={styles.delete}
                                onClick={() =>
                                  handleDeleteUser(user.id, user.username)
                                }
                              >
                                <Trash
                                  style={{ width: "20px", height: "20px" }}
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
              </div>
              <div className={styles.pagination_block}>
                <Pagination
                  totalPages={pageCount}
                  className={styles.pagination}
                  currentPage={page}
                  setCurrentPage={setPage}
                />

                <div className={styles.counter_block}>
                  <RowPerPage
                    options={rowsPerPageOptions}
                    selectedValue={totalString}
                    onChange={setTotalString}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>{t("Нет пользователей для отображения")}</div>
        )}
      </div>
    </>
  );
};
