import React, { useEffect, useState } from "react";
import { DashboardChart } from "./DashboardChart/DashboardChart";
import styles from "./Dashboard.module.css";
import { DashboardDomain } from "./DashboardDomain/DashboardDomain";
import { DashBoardChartCountry } from "../../../components/DashboardChartCountry/DashBoardChartCountry";
import Aside from "../../../components/Aside/Aside";
import { getData } from "../api";
import { Notes } from "../../../components/Notes/Notes";
import { useTranslation } from "react-i18next";
import { useUserData } from "../../../context/UserProvider";
import { ToastContainer } from "react-toastify";
import { useTheme } from "../../../components/ui/ChangeTheme/context/ThemeContext";
import { ChartUsers } from "./ChartUsers/ChartUsers";

const DashboardPage = () => {
  const { t } = useTranslation();
  const { userData } = useUserData();
  const { theme } = useTheme();

  const dataForChartChanger = [
    {
      name: t("День"),
      value: "day",
    },
    {
      name: t("Месяц"),
      value: "month",
    },
    {
      name: t("Год"),
      value: "year",
    },
    {
      name: t("Все время"),
      value: "all_time",
    },
  ];

  const [chartDataCountry, setChartDataCountry] = useState(null);
  const [selectedChartCountry, setSelectedChartCountry] = useState(
    dataForChartChanger[dataForChartChanger.length - 1].value
  );

  const [isOpenAsideNotes, setIsOpenAsideNotes] = useState(false);
  const [domainUrl, setDomainUrl] = useState(null);

  const handleOpenAside = (domain, bool) => {
    if (domainUrl !== domain) setDomainUrl(domain);
    setIsOpenAsideNotes(bool);
  };

  useEffect(() => {
    const getChartCountryData = async () => {
      const data = await getData({
        url: "/filter-by-countries",
        body: { date: selectedChartCountry },
      });

      if (data.error) {
        console.log(data.error);
        return;
      }

      let res = Object.keys(data).map((el) => ({
        name: el,
        value: data[el],
        flag: `https://flagsapi.com/${el}/flat/64.png`,
      }));

      setChartDataCountry(res);
    };
    getChartCountryData();
  }, [selectedChartCountry]);

  return (
    <>
      <ToastContainer theme={theme} position="top-center" />
      <div className={styles.container}>
        <DashboardChart />
        {userData?.role > 5 && (
          <DashBoardChartCountry
            dataForChartChanger={dataForChartChanger}
            chartData={chartDataCountry}
            selectedChart={selectedChartCountry}
            setSelectedChart={setSelectedChartCountry}
          />
        )}
        <ChartUsers
          dataForChartChanger={dataForChartChanger}
          chartData={chartDataCountry}
          selectedChart={selectedChartCountry}
          setSelectedChart={setSelectedChartCountry}
        />
        <DashboardDomain role={userData?.role} openAside={handleOpenAside} />
        <Aside
          width={320}
          title={t("Заметки")}
          isOpen={isOpenAsideNotes}
          setIsOpen={setIsOpenAsideNotes}
        >
          <Notes
            url={"/get-comments"}
            typeRequest={"domain"}
            refRequest={domainUrl}
          />
        </Aside>
      </div>
    </>
  );
};

export default DashboardPage;
